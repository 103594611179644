import { CheckBoxFilled } from '@finn/design-system/icons/check-box-filled';
import { CheckBoxOutlineBlank } from '@finn/design-system/icons/check-box-outline-blank';
import React from 'react';

interface IProps {
  checked: boolean;
  className?: string;
}

const Checkbox: React.FunctionComponent<IProps> = ({ className, checked }) => {
  const IconComponent = checked ? CheckBoxFilled : CheckBoxOutlineBlank;

  return <IconComponent className={className} />;
};

export default Checkbox;
